import React, { useState } from 'react';
import { InputActionMeta } from 'react-select';
import Table from '../Common/Table';
import { IVehiclesData } from '../../Interfaces/Vehicles';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import { Filters, FormValues, IColumn } from '../../Interfaces/form';
import TableHeading from '../Common/TableHeading';
import DynamicSearchForm from '../Common/DynamicSearch';
import {
  checkIfArrayIsTruthyOrNot,
  createSelectOptions,
  getLocalStorageAccountInfo,
  populateYears,
  renderNAonEmpty,
} from '../../Utils/utils';
import TableActions from '../Common/TableAction';
import VehicleView from './ViewVehicleListing';
import { localStorageEnums } from '../../Utils/enums';
import {
  ICountry,
  IState,
  ISubCategory,
  IUserSettingsData,
  IVehicleCategory,
} from '../../Interfaces/Settings';
import { ISelectOptions } from '../../Interfaces/Common/api.interface';
import ApplicationString from '../../Constants/applicationString';
import { UserDropdownEnum } from '../../Constants/dropdownEnums';

const VehicleListingComponent = (props: {
  allVehiclesData: IVehiclesData[];
  allUsersDataOptions: ISelectOptions[];
  pagination: IPaginationValues;
  isLoadingUserData: boolean;
  handleFilterUserSearch: (
    newValue: string,
    actionMeta: InputActionMeta
  ) => void;
  handleFilterDropdownSearch: (
    newValue: string,
    actionMeta: InputActionMeta
  ) => void;
  setPagination: (val: IPaginationValues) => void;
  setPaginationSortAndSearchTrigger: (val: boolean) => void;
  setSortParams: (val: ISortParamValues) => void;
  sortParams: ISortParamValues;
  searchValues: FormValues;
  handlePageChange: (value: number) => void;
  handlePageSizeChange: (value: number) => void;
  handleSearch: (values: FormValues) => void;
  handleVehicleViewClick: (data: IVehiclesData) => void;
  isVehicleDetailViewVisible: boolean;
  setIsVehicleDetailViewVisible: (val: boolean) => void;
  vehicleData: IVehiclesData;
  handleVehicleStatusClick: (vehicleId: number, isEnabledValue: string) => void;
  handleVehiclePopularStatusClick: (
    vehicleId: number,
    isPopularValue: boolean
  ) => void;
}): JSX.Element => {
  const {
    allVehiclesData,
    allUsersDataOptions,
    handleFilterUserSearch,
    handleFilterDropdownSearch,
    isLoadingUserData,
    pagination,
    setPagination,
    setPaginationSortAndSearchTrigger,
    setSortParams,
    sortParams,
    searchValues,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    handleVehicleViewClick,
    isVehicleDetailViewVisible,
    setIsVehicleDetailViewVisible,
    vehicleData,
    handleVehicleStatusClick,
    handleVehiclePopularStatusClick,
  } = props;

  const [fieldSearch, setFieldSearch] = useState(false);
  const [zIndex, setZIndex] = useState<boolean>(false);

  const settingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;

  const countriesData: ICountry[] = checkIfArrayIsTruthyOrNot<ICountry[]>(
    settingsData?.country
  );
  const statesData = countriesData.flatMap((con) =>
    checkIfArrayIsTruthyOrNot<IState[]>(con?.state)
  );
  const startYearForManufacturing = settingsData?.manufacturingYear.min;

  const vehicleCategoryData: IVehicleCategory[] = checkIfArrayIsTruthyOrNot<
    IVehicleCategory[]
  >(settingsData?.vehicleCategory);
  const vehicleSubCategoryData = vehicleCategoryData.flatMap((cat) =>
    checkIfArrayIsTruthyOrNot<ISubCategory[]>(cat?.subCategory)
  );

  const makerData = vehicleCategoryData.flatMap((mak) =>
    checkIfArrayIsTruthyOrNot<string[]>(mak?.maker)
  );

  const column: IColumn<IVehiclesData>[] = [
    {
      title: ApplicationString.module.vehicle.table.columns.id,
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    { title: 'Title', dataIndex: 'title', key: 'title', sorter: true },
    {
      title: ApplicationString.module.vehicle.table.columns.description,
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      render: (data) => {
        return renderNAonEmpty(data.description);
      },
    },
    {
      title: ApplicationString.module.vehicle.table.columns.isEnabled,
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      sorter: true,
      render: (data) => <span>{data?.isEnabled ? 'Yes' : 'No'}</span>,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.isPopular,
      dataIndex: 'isPopular',
      key: 'isPopular',
      sorter: true,
      render: (data) => <span>{data?.isPopular ? 'Yes' : 'No'}</span>,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.category,
      dataIndex: 'category',
      key: 'category',
      sorter: false,
      render: (data) => (
        <span>
          {
            vehicleCategoryData?.find(
              (i: { id: number }) => i.id === Number(data.category)
            )?.name
          }
        </span>
      ),
    },
    {
      title: ApplicationString.module.vehicle.table.columns.subCategory,
      dataIndex: 'subCategory',
      key: 'subCategory',
      sorter: false,
      render: (data) => {
        const subCategoryRecord = vehicleSubCategoryData?.find(
          (i: { id: number }) => i.id === Number(data.subCategory)
        )?.name;
        return <span>{subCategoryRecord}</span>;
      },
    },
    {
      title: ApplicationString.module.vehicle.table.columns.maker,
      dataIndex: 'maker',
      key: 'maker',
      sorter: true,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.model,
      dataIndex: 'model',
      key: 'model',
      sorter: true,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.manufacturingYear,
      dataIndex: 'manufacturingYear',
      key: 'manufacturingYear',
      sorter: true,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.country,
      dataIndex: 'country',
      key: 'country',
      sorter: true,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.state,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.city,
      dataIndex: 'city',
      key: 'city',
      sorter: true,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.zip,
      dataIndex: 'zip',
      key: 'zip',
      sorter: false,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.address,
      dataIndex: 'address',
      key: 'address',
      sorter: true,
    },
    {
      title: ApplicationString.module.vehicle.table.columns.latLong,
      dataIndex: 'latLong',
      key: 'latLong',
      sorter: false,
      render: (data) => {
        return renderNAonEmpty(data.latLong);
      },
    },
    {
      title: ApplicationString.module.vehicle.table.columns.action,
      dataIndex: 'action',
      key: 'action',
      sorter: false,
      fixed: 'right',
      render: (data) => {
        return (
          <TableActions
            onView={() => handleVehicleViewClick(data)}
            // onEdit={data.id}
            editId={data.id}
            onStatusChange={(isEnabledValue: string) =>
              handleVehicleStatusClick(data.id, isEnabledValue)
            }
            onPopularStatusChange={(isPopularValue: boolean) =>
              handleVehiclePopularStatusClick(data.id, isPopularValue)
            }
            setZIndex={setZIndex}
            isEnabled={data.isEnabled}
            statusBool
            isPopular={data.isPopular}
            popularStatus
            // editBool
            viewBool={data.isEnabled}
            isOwnerApproved={false}
            approveBool={false}
            enableBool={false}
            deleteBool={false}
            resetPassBool={false}
            text={ApplicationString.module.vehicle.title}
          />
        );
      },
    },
  ];

  const fieldCol: Filters[] = [
    {
      key: 'column1',
      label: ApplicationString.module.vehicle.table.filters.manufacturingYear,
      name: 'manufacturingYear',
      type: 'common-dropdown',
      options: populateYears(startYearForManufacturing),
    },
    {
      key: 'column2',
      label: ApplicationString.module.vehicle.table.filters.maker,
      name: 'maker',
      type: 'common-dropdown',
      options: makerData.map((i) => ({ label: i, value: i })),
    },
    {
      key: 'column3',
      label: ApplicationString.module.vehicle.table.filters.model,
      name: 'model',
      type: 'text',
      options: [],
    },
    {
      key: 'column4',
      label: ApplicationString.module.vehicle.table.filters.city,
      name: 'city',
      type: 'text',
      options: [],
    },
    {
      key: 'column5',
      label: ApplicationString.module.vehicle.table.filters.country,
      name: 'country',
      type: 'common-dropdown',
      options: createSelectOptions<ICountry, ICountry[], 'code', 'name'>(
        countriesData,
        'code',
        'name'
      ),
    },
    {
      key: 'column6',
      label: ApplicationString.module.vehicle.table.filters.state,
      name: 'state',
      type: 'common-dropdown',
      options: createSelectOptions<IState, IState[], 'code', 'name'>(
        statesData,
        'code',
        'name'
      ),
    },
    {
      key: 'column7',
      label: ApplicationString.module.vehicle.table.filters.category,
      name: 'category',
      type: 'common-dropdown',
      options: createSelectOptions<
        IVehicleCategory,
        IVehicleCategory[],
        'id',
        'name'
      >(vehicleCategoryData, 'id', 'name'),
    },
    {
      key: 'column8',
      label: ApplicationString.module.vehicle.table.filters.subCategory,
      name: 'subCategory',
      type: 'common-dropdown',
      options: createSelectOptions<ISubCategory, ISubCategory[], 'id', 'name'>(
        vehicleSubCategoryData,
        'id',
        'name'
      ),
    },
    {
      key: 'column9',
      label: ApplicationString.module.vehicle.table.filters.user,
      name: 'accountId',
      type: 'searchable-dropdown',
      options: allUsersDataOptions,
    },
    {
      key: 'column10',
      label: ApplicationString.module.vehicle.table.filters.isEnabled,
      name: 'isEnabled',
      type: 'radio',
      options: UserDropdownEnum,
    },
  ];

  const handleFilterClick = () => {
    setFieldSearch(!fieldSearch);
  };

  return (
    <>
      <TableHeading
        hideFilter={false}
        title={ApplicationString.table.heading.vehicleManagement}
        fieldSearch={fieldSearch}
        onFilterClick={() => handleFilterClick()}
      />
      {fieldSearch && (
        <DynamicSearchForm
          inputColumn={fieldCol}
          onSearch={handleSearch}
          setPagination={setPagination}
          pagination={pagination}
          savedFilteredValues={searchValues}
          isLoadingUserData={isLoadingUserData}
          handleFilterUserSearch={handleFilterUserSearch}
          handleFilterDropdownSearch={handleFilterDropdownSearch}
        />
      )}
      <Table<IVehiclesData>
        dataSource={allVehiclesData}
        columns={column}
        pageSize={pagination.pageSize}
        onPageSizeChange={handlePageSizeChange}
        totalItems={pagination.totalItems}
        onPageChange={handlePageChange}
        zIndex={zIndex}
        setZIndex={setZIndex}
        totalRecords={pagination.totalItems}
        setSortParams={setSortParams}
        sortParams={sortParams}
        setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
        setPagination={setPagination}
        pagination={pagination}
      />

      {isVehicleDetailViewVisible && (
        <VehicleView
          onDrawerClose={() => {
            setIsVehicleDetailViewVisible(false);
          }}
          vehicleData={vehicleData}
          title={ApplicationString.module.vehicle.vehicleViewDetails.title}
        />
      )}
    </>
  );
};

export default VehicleListingComponent;
